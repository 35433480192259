import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import CaregiversForm from "../components/caregiversForm";
import Title from "../components/title";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { FaRegWindowClose } from "react-icons/fa";
export const _frontmatter = {
  "title": "Caregivers",
  "calltoaction": "Tell us what you need and we'll find the right caregiver for you",
  "description": "Myriad employs empathetic and sociable individuals. We guarantee that our caregivers are kind, organized, and understanding.",
  "tags": ["caregivers", "support", "request"],
  "path": "/caregivers",
  "date": "2019-11-07T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ModalRoutingContext.Consumer>
  {({
        modal,
        closeTo
      }) => <div>
      {modal ? <>
        <Link to={closeTo} mdxType="Link">
            Close
        </Link>
        <Link to={closeTo} mdxType="Link">
          <FaRegWindowClose style={{
              float: `right`
            }} title="Close window" mdxType="FaRegWindowClose" />
        </Link>
        <div className="modal-header" style={{
            backgroundColor: `#000099`,
            color: `white`
          }}>
          <h1 className="modal-title">{props.pageContext.frontmatter.title}</h1>
          <p className="modal-description">{props.pageContext.frontmatter.description + " " + props.pageContext.frontmatter.calltoaction}.</p>
        </div>
        <CaregiversForm mdxType="CaregiversForm" /></> : <Layout mdxType="Layout">
          <Title title={props.pageContext.frontmatter.title} filename="sunset.jpg" calltoaction={props.pageContext.frontmatter.calltoaction} mdxType="Title" />
          <SEO title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="SEO" />
          <div className="centered-content">  
              <p className="form-description">{props.pageContext.frontmatter.description}</p>
              <CaregiversForm mdxType="CaregiversForm" />
          </div>
        </Layout>}
      </div>}
    </ModalRoutingContext.Consumer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      